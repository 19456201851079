<template>
    <div :class="isDark?'white--text':'grey--text'">
        <template v-if="shopData?.show_lnwme_footer">
            <a :class="isDark?'white--text':'grey--text'"
               href="https://www.lnwshop.com/app/chat_commerce">{{ $t('บิลออนไลน์') }}</a><span class="pl-1">© {{ new Date().getFullYear() }} </span><img style="height: 11px;" src="@/assets/lnw_logo.png"/>
        </template>
        <template v-else-if="shopData">
            <a
               :href="shopData.url">
                <img class="rounded" :src="shopData.favicon"/> {{ shopData.name }}</a><span class="pl-1">© {{ new Date().getFullYear() }} </span>
        </template>
        <template v-else>
            <a href="/">{{ $t('Lnw.Me') }}</a><span class="pl-1">© {{ new Date().getFullYear() }} </span><img style="height: 11px;" src="@/assets/lnw_logo.png"/>
        </template>
    </div>
</template>
<script>
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import {mapState} from "vuex";

export default {
    name: "FooterComponent",
    mixins: [MixinsHelper],
    data: function (){
        return {}
    },
    watch: {},
    computed: {
        ...mapState({
            shopData: state => state.order.shopData ? state.order.shopData : (state.myOrders.shopData),
        }),

    },
    methods: {},
    mounted: function (){
        //this.scroll_to_bottom();
    },
}
</script>
<style scoped></style>